@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');


body {
    font-family: 'Poppins', sans-serif ;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}


/* login page css */

.login-form-container {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    padding: 15px;
}

.ghadi-logo {
    max-width: 180px;
    margin-bottom: 20px;
}

.btn {
    background: #029FEE; 
    color: #fff;
    border-radius: 5px;
    border-color: #029FEE;
}

/* .btn:hover {
    background: #029FEE; 
} */

.powered-by {
    display: flex;
}

.channelier-logo {
    max-width: 180px;
    margin-left: 20px;
}

/* otp page */

.section_title {
    font-size: 20px;
    font-weight: 600;
}

.form-label {
    color: #000;
    font-weight: 500;
    font-size: 15px;
}

.mobile-input {
    position: relative;
}

.edit-icon {
    position: absolute;
    right: 15px;
    top: 40px;
    max-width: 15px;
}

.arrow-icon svg {
    font-size: 35px;
}

.otp-container {
    margin-top: 15px;
}

.txt {
    margin: 5px 0;
}


.redeem_img_box h6 {
    margin-bottom: 5px;
    color: #029FEE;
    font-weight: 400;
}

.plus {
    font-size: 17px;
    margin-bottom: 5px;
}

.fw-600 {
    font-weight: 600 !important;
}

.redeem_logo {
    max-width: 160px;
    margin-bottom: 10px;
}

.reedem_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
}

.reedem_box {
    width: 49%;
    border: 1px solid #A7A7A7;
    background: #E6F6FD;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    min-height: 140px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.reedem_box h6 {
    color: #029FEE;
    font-weight: 600;
    margin-bottom: 5px;
}

.reedem_box p {
    margin-bottom: 5px;
    line-height: 1.2;
}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {

    .ghadi-logo {
        max-width: 180px;
        margin-bottom: 50px;
    }

    .login-form-button {
        width: 100%;
    }

    .powered-by {
        display: flex;
        align-items: center;
        margin-top: 10px;
        justify-content: center;
    }
    
    .otp-verify-button {
        width: 100%;
    }

    .reedem_box {
        width: 46%;
    }

}


/* ===================Mobile Landscape Size to Tablet Portrait (devices and browsers)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}

/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {

}